import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"];

  initialize(){
    this.options = {
      legend: {
        display: true,
        labels: {
            align: 'start',
            fontColor: '#333333',
            padding: 20,
            position: 'left'
        }
      },
      scales: {
       yAxes: [{
        gridLines: {
         color: "rgba(0, 0, 0, .05)"
        }
       }]
      }
     }
  }

  connect() {
    const keys = JSON.parse(this.data.get("keys"));
    const datasets = JSON.parse(this.data.get("datasets"));

    new Chart(this.contentTarget, {
      type: 'line',
      data: {
          labels: keys,
          datasets: datasets
      },
      options: this.options
    });
  }
}
