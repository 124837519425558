import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  initialize(){
    this.backgroundColors = [
      'rgba(255, 99, 132, 0.5)',
      'rgba(244, 140, 6, 0.5)', // orange
      'rgba(36, 130, 15, 0.5)', // green 
      'rgba(153, 102, 255, 0.5)', // yellow
      'rgba(255, 0, 0, 0.5)', // red
      'rgba(54, 162, 235, 0.5)', // blue
      'rgba(201, 203, 207, 0.5)'
    ];

    this.borderColors = [
      'rgb(255, 99, 132)',
      'rgb(244, 140, 6)', // orange niger
      'rgb(36, 130, 15)', // green
      'rgb(153, 102, 255)', // yellow
      'rgb(255, 0, 0)', // red
      'rgb(54, 162, 235)', // blue
      'rgb(201, 203, 207)'
    ];

    this.defaultOptions = {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          fontColor: '#333333',
          padding: 16,
          position: 'left',
          fontSize: 18
        }
      }
    };

    this.lineChartOptions = {
      legend: {
        display: true,
        labels: {
          align: 'start',
          fontColor: '#333333',
          padding: 20,
          position: 'left'
        }
      },
      scales: {
        yAxes: [{
          gridLines: {
            color: "rgba(0, 0, 0, .05)"
          }
        }]
      }
    };
  }

  connect() {
    const chartType = this.data.get("chartType");
    const keys = JSON.parse(this.data.get("keys"));
    const datasets = JSON.parse(this.data.get("datasets"));
    const colors = JSON.parse(this.data.get("colors"));

    let options = {};

    if (chartType === 'line') {
      options = this.lineChartOptions;
    } else {
      options = this.defaultOptions;
    }
    

    const chartData = {
      labels: keys,
      datasets: datasets
    };

    if (chartType === 'doughnut') {
      chartData.datasets = [{
        label: 'My First Dataset',
        data: datasets[0].data,
        backgroundColor: colors || this.borderColors,
        hoverOffset: 8
      }];
    }

    if (chartType === 'bar') {
      chartData.datasets =[{
        label: datasets[0].label,
        data: datasets[0].data,
        backgroundColor: this.backgroundColors,
        borderColor: this.borderColors,
        borderWidth: 1}];
    }

    new Chart(this.contentTarget, {
      type: chartType,
      data: chartData,
      options: options
    });
  }
}
