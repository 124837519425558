import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'fieldId', 'timelineId' ];

  onChange(e) {
    e.preventDefault();
    if (e.target.name == 'timeline_id') {
      window.location = `/jury_access/juries/first_group_notes?field_id=${this.fieldIdTarget.value}&timeline_id=${this.timelineIdTarget.value}`;
    } else {
      window.location = `/jury_access/juries/first_group_notes?field_id=${this.fieldIdTarget.value}`;
    }
  }
}
