import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'fieldId' ];

  onChange(e) {
    e.preventDefault();
    window.location = `/jury_access/juries/eps_notes?field_id=${this.fieldIdTarget.value}`;
  }
}
