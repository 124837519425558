import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "destroy" ];

  connect(){
    this.element.style.display = "flex";
  }

  destroy(e) {
    e.preventDefault();
    this.element.style.display = "none";
  }
}