import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    this.field = this.data.get('field');
    this.school = this.data.get('school');
    this.region = this.data.get('region');
    this.select = this.data.get('select');
    this.selected = this.data.get('selected');
    this.fieldElt = document.querySelector(this.field);
    this.regionElt = document.querySelector(this.region);
    this.schoolElt = document.querySelector(this.school);
    this.selectElt = document.querySelector(this.select);
    this.url = this.data.get('url');
    // this.fieldElt.addEventListener("change", this.loadChild.bind(this));
  }

  loadChild() {
    const fieldId = this.fieldElt.value;
    const schoolId = this.schoolElt.value;
    const regionId = this.regionElt.value;
    Rails.ajax({
      url: `${this.url}?field_id=${fieldId}&school_id=${schoolId}&region_id=${regionId}`,
      type: "get",
      success: ( (data) => this.onLoad(data) )
    });
  }

  onLoad(data) {
    const pasedData = data.map(e => { return {'value': e.value, 'label': e.label, 'selected': (e.value == this.selected ? true : false)} });
    this.selectElt.choices.clearStore();
    this.selectElt.choices.setChoices(pasedData);
  }
}
