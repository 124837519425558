import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"];

  initialize(){
    this.options = {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          fontColor: '#333333',
          padding: 16,
          position: 'left',
          fontSize: 18
        }
      }
    }
  }

  connect() {
    const keys = JSON.parse(this.data.get("keys"));
    const datasets = JSON.parse(this.data.get("datasets"));
    const colors = JSON.parse(this.data.get("colors"));

    new Chart(this.contentTarget, {
      type: 'doughnut',
      data: {
          labels: keys,
          datasets: [{
            label: 'My First Dataset',
            data: datasets,
            backgroundColor: colors,
            hoverOffset: 8
          }]
      },
      options: this.options
    });
  }
}
