import { Controller } from "stimulus"
import domtoimage from 'dom-to-image';

export default class extends Controller {

  download(e) {
    e.preventDefault();
    const node = document.getElementById('jury-capture');
    const indice = node.dataset.indice;
    gtag('event', 'statistics', {'event_label': `JURY ${indice}`});
    domtoimage.toJpeg(node, { quality: 10 })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        var current_year = new Date().getFullYear();
        link.download = `statistique-jury-${indice}-bac-${current_year}-ifutur.jpeg`;
        link.href = dataUrl;
        link.click();
    });
  };

  downloadRegion(e) {
    e.preventDefault();
    const node = document.getElementById('region-capture');
    const name = node.dataset.name;
    gtag('event', 'statistics', {'event_label': `Région ${name}`});
    domtoimage.toJpeg(node, { quality: 10 })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `statistique-region-${name}-bac-2022-ifutur.jpeg`;
        link.href = dataUrl;
        link.click();
    });
  }
}
