import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(".select2.select2-container.select2-container--default").remove();
    $(".custom-select").select2();
  }

  disconnect() {
    if ($('.custom-select').hasClass("select2-hidden-accessible")) {
      $('.custom-select').select2('destroy');
    }
  }
}
