import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'preview', 'fileName', 'sizeError' ];

  connect() {
    this.file = this.element.querySelector('input');
    this.body = document.querySelector('body');
    this.element.addEventListener("direct-upload:start", this.uploadStart.bind(this));
    this.element.addEventListener("direct-upload:progress", this.uploadProgress.bind(this));
    this.element.addEventListener("direct-upload:error", this.uploadError.bind(this));
    this.element.addEventListener("direct-upload:end", this.uploadEnd.bind(this));
    this.file.addEventListener("change", this.fileChange.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("direct-upload:start", this.uploadStart.bind(this));
    this.element.removeEventListener("direct-upload:progress", this.uploadProgress.bind(this));
    this.element.removeEventListener("direct-upload:error", this.uploadError.bind(this));
    this.element.removeEventListener("direct-upload:end", this.uploadEnd.bind(this));
    this.file.removeEventListener("change", this.fileChange.bind(this));
  }

  uploadStart(e) {
    this.fileNameTarget.classList.add('uploading');
    this.fileNameTarget.textContent = 'Téléchargement...';
  }

  uploadProgress(e) {
    this.fileNameTarget.textContent = `Téléchargement... ${parseInt(e.detail.progress)}%`;
  }

  uploadError(e) {
    this.fileNameTarget.classList.remove('uploading');
    alert('Erreur durant le téléchargement');
  }

  uploadEnd(e) {
    this.fileNameTarget.textContent = e.target.files[0].name;
  }

  fileChange(e) {
    const file = e.target.files[0];
    const filesize = file.size;

    if (filesize > 2000000) {
      this.sizeErrorTarget.classList.remove('d-none');
      this._clearFileInput();
      return;
    }
    else {
      this.sizeErrorTarget.classList.add('d-none');
    }

    this.fileNameTarget.textContent = file.name;
    this.fileNameTarget.classList.add('is-loading');

    if (!file.type.startsWith('image/') || !this.hasPreviewTarget ) { return; }

    let reader = new FileReader();

    reader.onload = (function(img) {
      return function(e) {
        img.src = e.target.result;
      };
    })(this.previewTarget);

    reader.readAsDataURL(file);
  }

  _clearFileInput() {
    try {
      this.file.value = null;
    } catch(ex) { }
    if (this.file.value) {
      this.file.parentNode.replaceChild(this.file.cloneNode(true), this.file);
    }
    this.file = this.element.querySelector('input');
    console.log(this.file);
    this.fileNameTarget.textContent = this.fileNameTarget.dataset['placeholder'];
  }
}
