const initDatepicker = (elt) => {
  return flatpickr(elt, {
    dateFormat: "Y/m/d",
    altInput: true,
    altFormat: "d-m-Y",
    "locale": "fr"
  });
}

const initTimepicker = (elt) => {
  return flatpickr(elt, {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
    "locale": "fr"
  });
};

export { initDatepicker, initTimepicker };
