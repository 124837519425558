import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["firstLanguage", "secondLanguage" ]

  connect() {
    this.checkSecondLanguage()
    document.querySelector(".field-selection").addEventListener("change", this.checkSecondLanguage.bind(this));
    this.checkGeneralField()
    document.querySelector(".field-category-selection").addEventListener("change", this.checkGeneralField.bind(this));
    // this.setLanguages();
    this.firstLanguageTarget.addEventListener("change", this.firstLanguageChange.bind(this));
  }

  disconnect() {
    this.firstLanguageTarget.removeEventListener("change", this.firstLanguageChange.bind(this));
  }

  firstLanguageChange() {
    const value = this.firstLanguageTarget.value;
    console.log(value);
    if (this.hasSecondLanguageTarget) {
      Array.from(this.secondLanguageTarget.querySelectorAll('option')).forEach(function(elt){
        elt.classList.remove('is-hidden');
      });
      this.secondLanguageTarget.querySelector(`option[value=${value}]`).classList.add('is-hidden');
      if ( this.firstLanguageTarget.value == this.secondLanguageTarget.value ) {
        this.secondLanguageTarget.value = '';
      }
    }
  }

  setLanguages() {
    this.firstLanguageChange();
    if ( this.firstLanguageTarget.value == this.secondLanguageTarget.value ) {
      this.secondLanguageTarget.value = '';
    }
  }
  
  inscriptionTypeChange(){
    const inscriptionType = document.querySelector(".inscription-type").value
    if (inscriptionType == 'free_candidate'){
      document.querySelector(".school-block").classList.add("is-hidden")
    }
    if(inscriptionType == 'student_candidate'){
      document.querySelector(".school-block").classList.remove("is-hidden")
    }
  }
  
  checkSecondLanguage(){
    const field = document.querySelector(".field-selection").value
    const scientistFields = document.querySelector(".scientist-fields").value
    if(JSON.parse(scientistFields).includes(parseInt(field))){
      document.querySelector(".second-language-block").classList.add("is-hidden")
    }else{
      document.querySelector(".second-language-block").classList.remove("is-hidden")
    }
  }

  checkGeneralField(){
    const bruteValue = document.querySelector(".field-category-brute").value
    const value = document.querySelector(".field-category-selection").value
    if(bruteValue == value){
      document.querySelector(".economie-block").classList.remove("is-hidden")
    }else{
      document.querySelector(".economie-block").classList.add("is-hidden")
    }
  }
}
