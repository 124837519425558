import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "firstGroupRate", "firstGroupLoader", "secondGroupRate", "secondGroupLoader", "secondGroupSection", "firstGroupJuries", "secondGroupJuries", "general", "technical", "professionnal" ]

  connect() {
    var alertOn = this.element.offsetTop;
    var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    alertOn = alertOn - (vh * 0.6)
    if (window.scrollY > alertOn) {
      this.loadData();
    } else {
      // Store the bound function reference
      this.boundTestScroll = this.testScroll.bind(this);
      window.addEventListener('scroll', this.boundTestScroll, { passive: true });
    }
  }

  testScroll() {
    var place = window.scrollY;
    var alertOn = this.element.offsetTop;
    var vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    alertOn = alertOn - (vh * 0.6)
    if (place > alertOn) {
      // Remove the event listener using the stored reference
      window.removeEventListener('scroll', this.boundTestScroll, { passive: true });

      this.loadData();
    }
  }

  loadData() {
    fetch('/api/v1/results')
    .then((response) => response.json())
    .then((json) => {
      this.firstGroupJuriesTarget.innerHTML = `${json["first_group_publicated_juries"]} jury(s) sur ${json["total_juries"]}`;
      // this.counter(this.firstGroupRateTarget, json["first_group_rate"])      
      this.firstGroupLoaderTarget.style.width = `${json["first_group_rate"]}%`;
      if(json["first_group_rate"] == 100) {
        this.secondGroupSectionTarget.style.display = 'flex';
        const reflow = this.element.offsetHeight;
        this.secondGroupLoaderTarget.style.width = `${json["second_group_rate"]}%`;
        // this.counter(this.secondGroupRateTarget, json["second_group_rate"])      
        this.secondGroupJuriesTarget.innerHTML = `${json["second_group_publicated_juries"]} jury(s) sur ${json["total_juries"]}`;
      }
      // var stats = json["statistics"];
      // stats.forEach(elt => {
      //   if(elt[0].includes('GENERAL')) this.counter(this.generalTarget, elt[1])
      //   if(elt[0].includes('TECHNIQUE')) this.counter(this.technicalTarget, elt[1])
      //   if(elt[0].includes('PROFESSIONNEL')) this.counter(this.professionnalTarget, elt[1])
      // })
    })
    .catch(error => console.log('Error:', error));
  }

  counter(elt, value) {
    let i = 0.0;
    let interval = setInterval(() => {
      if (i >= value) {
        clearInterval(interval);
        elt.innerHTML = `${value.toFixed(2)}%`;
      } else {
        i += 0.4;
        elt.innerHTML = `${i.toFixed(2)}%`;
      }
    })
  }
}
