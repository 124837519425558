import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    document.getElementById('rejectModal').classList.add('is-active');
  }

  close(){
    document.getElementById('rejectModal').classList.remove('is-active');
  }

  send(event) {
    event.preventDefault();
   
    let description = document.getElementById('rejectDescription').value;
   
    if (!description) {
      alert("La justification est obligatoire.");
      return;
    }
   
    let candidateId = event.target.dataset.id;
    let url = `/secretary_candidates/${candidateId}/init_reject_inscription`;
    let params = new URLSearchParams({ reject_description: description });
   
    fetch(`${url}?${params.toString()}`)
    .then(response => response.redirected ? window.location.href = response.url : response.text())
    .catch(error => console.log('Error:', error));
   }
   
   
    
}