import ApplicationController from './application_controller'


export default class extends ApplicationController {
  static targets = ['subject', 'diploma', 'other_diploma'];


  connect () {
    const teachingTypeSelect = this.element.querySelector('select[name="corrector[teaching_type]"]')
    const diplomaSelect = this.element.querySelector('select[name="corrector[diploma]"]')

    this.change({ target: teachingTypeSelect })
    this.change_diploma({ target: diplomaSelect })
  }

  change(event) {
   
    const selectedTeachingType = event.target.value
    const generalInputs = document.querySelectorAll('.general-input')
    const technicalInputs = document.querySelectorAll('.technical-input')
    const professionalInputs = document.querySelectorAll('.professional-input')

    generalInputs.forEach(input => input.classList.add('d-none'))
    technicalInputs.forEach(input => input.classList.add('d-none'))
    professionalInputs.forEach(input => input.classList.add('d-none'))
    
    if (selectedTeachingType === 'general') {
      generalInputs.forEach(input => input.classList.remove('d-none'))
      this.subjectTarget.classList.remove('d-none')
    } else if (selectedTeachingType === 'technical') {
      technicalInputs.forEach(input => input.classList.remove('d-none'))
      this.subjectTarget.classList.remove('d-none')
    } else if (selectedTeachingType === 'professional') {
      professionalInputs.forEach(input => input.classList.remove('d-none'))
      this.subjectTarget.classList.remove('d-none')
    }
    else{
      this.subjectTarget.classList.add('d-none')
    }
  }

  change_diploma(e){
    const diploma_type = e.target.value
    if (diploma_type === 'other') {
      this.other_diplomaTarget.classList.remove('d-none')
    } else {
      this.other_diplomaTarget.classList.add('d-none')
    }
  }
}
