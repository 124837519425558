import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'firstStep', 'secondStep', 'thirdStep', 'fourthStep', 'fithStep', 'paymentPhone', 'phoneError', 'step', 'paymentBusyStep', 'paymentForm', 'paymentButton', 'paymentOption', 'publicReference'];

  connect() {
    this.currentStep = this.data.get('step');
    this.paymentOption = 'mobile';
    let feeErrorTarget = document.querySelector('#feeError')
    if (this._isValidFee()) {
      feeErrorTarget.classList.add('d-none');
      this.element.classList.remove('d-none')
    }
    else {
      this.element.classList.add('d-none')
      feeErrorTarget.classList.remove('d-none');
    }
  }

  updatePaymentOption(e) {
    this.paymentOption = e.currentTarget.getAttribute('for');
  }

  backToStepOne() {
    this._displayStep('first');
  }

  backToStepTwo() {
    this._displayStep('second');
  }

  backToStepThree() {
    this._displayStep('third');
  }

  backToStepFour() {
    this._displayStep('fourth');
  }

  backToStepFive() {
    this._displayStep('fith');
  }

  createPayment(e) {
    e.preventDefault();
    if (this._isValidPhone()) {
      this._submitPayment()
    }
    else {
      this.phoneErrorTarget.classList.remove('d-none');
    }
  }

  createBulkPayment(e) {
    e.preventDefault();
    // this.paymentOption = 'alizza'; 
    if (this._witch_check()) {
      this._submitPayment()
    }
    else {
      this.phoneErrorTarget.classList.remove('d-none');
    }
  }

  _submitPayment() {
    this.phoneErrorTarget.classList.add('d-none');
    this.paymentButtonTarget.setAttribute('disabled', true);

    fetch(`${this.data.get('url')}?payment_phone=${this.paymentPhoneTarget.value}&payment_provider=${this.paymentOption}`)
    .then(response => response.json())
    .then(data => {
      console.log(data)
      console.log(data['payment'])
      if (this.paymentOption == 'alizza') {
        this.publicReferenceTarget.textContent = data['payment']['public_reference'];
      }
      this.paymentFormTarget.classList.add('d-none');
      this.paymentBusyStepTarget.classList.remove('d-none');
    })
  }

  _isValidPhone() {
    let phoneFormat = /^(96|97|98|99|87|88|89|77|74|75|76|84|85|94|95|70|80|81|90|92|86|)[0-9]{6}$/;
    return this.paymentPhoneTarget.value.match(phoneFormat);
  }

  _displayCurrentStep() {
    if (this.currentStep ==  '1') {
      this._displayStep('first');
    }
    else if (this.currentStep ==  '2') {
      this._displayStep('second');
    }
    else if (this.currentStep ==  '3') {
      this._displayStep('third');
    }
    else if (this.currentStep ==  '4') {
      this._displayStep('fourth');
    }
    else if (this.currentStep ==  '5') {
      this._displayStep('fith');
    }
    else if (this.currentStep ==  '6') {
      this._displayStep('sixth');
    }
  }

  _displayStep(step) {
    this.stepTargets.forEach(elt => elt.classList.add('d-none'));
    this[`${step}StepTarget`].classList.remove('d-none');
  }

  _witch_check(){
    if (this.paymentOption == 'mobile') {
      let phoneFormat = /^(96|97|98|99|87|88|89|76)[0-9]{6}$/;
      return this.paymentPhoneTarget.value.match(phoneFormat) != null;
    } else {
      return this._isValidPhone()
    }
  }

  _isValidFee(){
    return 1000000  >= this.data.get('fee')
  }
}