import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'link' ];

  select(e) {
    e.preventDefault();
    const value = this.linkTarget.dataset.value;
    document.querySelector('#region-statistic-details').src = `region/statistics?region_id=${value}`
  }
}
