import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "jury", "region", "bacType", "field" ]
  onChange(e) {
    e.preventDefault();

    this.juryTarget.classList.add('is-hidden');
    this.regionTarget.classList.add('is-hidden');
    this.bacTypeTarget.classList.add('is-hidden');
    this.fieldTarget.classList.add('is-hidden');

    const value = e.target.value;

    if (value == 'jury') {
      this.juryTarget.classList.remove('is-hidden');
    }

    if (value == 'region') {
      this.regionTarget.classList.remove('is-hidden');
    }

    if (value == 'bac_type') {
      this.bacTypeTarget.classList.remove('is-hidden');
    }

    if (value == 'field') {
      this.fieldTarget.classList.remove('is-hidden');
    }
  }
}
