import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const choicesElement = document.querySelectorAll('.js-choice');
    choicesElement.forEach(elt => {
      elt.choices = new Choices(elt, {
        placeholderValue: 'Sélectionner un élément',
        searchPlaceholderValue: 'Chercher un élément',
        itemSelectText: '',
        loadingText: 'Chargement...',
        noResultsText: 'Aucun résultat',
        noChoicesText: 'Patientez nous sommes en train de charger les données'
      });
    });
  }
}
