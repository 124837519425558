import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'recordsList', 'recordTemplate' ];

  addNewRecord() {
    this.recordsListTarget.appendChild(this._newRecordTemplate());
  }

  deleteRecord(e) {
    const element = e.currentTarget.closest('.single-record');
    const isNewRecord = element.dataset['new_record'] == 'true';
    if (isNewRecord) {
      this.recordsListTarget.removeChild(e.currentTarget.closest('.single-record'));
    }
    else {
      element.classList.add('d-none');
      element.querySelector('.delete-target').value = 1;
    }
    this._createNewRecordIfNeeded();
  }

  _newRecordTemplate() {
    const template = this.recordTemplateTarget.textContent.replace(/NEW_RECORD/g, new Date().getTime());
    return new DOMParser().parseFromString(template, 'text/html').body.firstChild;
  }

  _createNewRecordIfNeeded() {
    if (this._activeRecordsNotPresent()) this.addNewRecord();
  }

  _activeRecordsNotPresent() {
    return !this.recordsListTarget.querySelectorAll('.single-record:not(.d-none)')[0];
  }
}
