import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"];

  connect(){
  }

  openTab(evt, tabName) {
    let i= 0;
    let tablinks = '';
    const x = document.getElementsByClassName("content-tab");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < x.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " is-active";
  }
}